



























import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'
import Auth from '@/shared/storeModules/auth'
import moment from 'moment'
import { ICalendarEvent, IDayCalendarEvent } from '@/shared/components/interfaces/calendar.interface'
import { IFullCalendarEvent } from '@/shared/components/interfaces/calendar.interface'
import CalendarApi from '@/shared/services/api/calendarApi'
import { getMobileOrDesktop } from '../services/helpers/destopOrMobile'
import FullCalendar, { CalendarOptions } from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction'
import DesktopCalendar from '@/components/calendar/DesktopCalendar.vue'
import EventDetails from '@/components/calendar/EventDetails.vue'
import MobileCalendar from '@/components/calendar/MobileCalendar.vue'
import CalendarEventItemV2Model from '@/shared/models/CalendarEventItemV2Model'

const userState = getModule(Auth)

@Component({
  components: { FullCalendar, EventDetails, DesktopCalendar, MobileCalendar }
})
export default class Calendar extends Vue {
  userState = userState
  isMobileDevice: boolean = document.body.clientWidth <= 1024
  apiCalendarEvents: IDayCalendarEvent = {}
  calendarEvents: ICalendarEvent[] = []
  events: IFullCalendarEvent[] = []
  eventsV2: IFullCalendarEvent[] = []
  daysWithEvent: { start: string }[] = []
  activeDate = moment()
  $refs: any

  get datePickerOptions(): CalendarOptions {
    return {
      plugins: [dayGridPlugin, interactionPlugin],
      initialView: 'dayGridMonth',
      locale: `${this.$locale}`,
      firstDay: 1,
      dateClick: this.handleDateSelect,
      headerToolbar:
        getMobileOrDesktop() === 'mobile'
          ? { start: 'title', end: 'prev,today,next' }
          : { start: 'prev', center: 'title', end: 'next' },
      customButtons: {
        next: {
          click: this.nextDatePickerMonth
        },
        today: {
          text: `${this.$t('buttons.today')}`,
          click: this.goToTodayDatePicker
        },
        prev: {
          click: this.prevDatePickerMonth
        }
      },
      eventSources: [JSON.parse(JSON.stringify(this.daysWithEvent))],
      height: '100%',
      contentHeight: '100%',
      selectable: true,
      eventDisplay: 'list-item',
      dayHeaderContent: args => {
        return moment(args.date).format('ddd').slice(0, 1)
      },
      dayCellContent: args => {
        return moment(args.date).format('D')
      }
    }
  }

  next() {
    this.$refs.calendar.$refs.fullCalendar.getApi().next()
    if (getMobileOrDesktop() === 'desktop') {
      this.activeDate = moment(this.activeDate).add(1, 'month').startOf('month')
    } else {
      this.activeDate = moment(this.activeDate).add(1, 'days')
    }
    this.getEvents(moment(this.activeDate))
    this.$amplitude.logEvent('Calendar', { Action: 'Next month' })
  }

  goToToday() {
    this.$refs.calendar.$refs.fullCalendar.getApi().today()
    this.activeDate = moment()
    this.getEvents(moment(this.activeDate))
    this.$amplitude.logEvent('Calendar', { Action: 'Today' })
  }

  prev() {
    if (getMobileOrDesktop() === 'desktop') {
      this.activeDate = moment(this.activeDate).subtract(1, 'month').startOf('month')
    } else {
      this.activeDate = moment(this.activeDate).subtract(1, 'days')
    }
    this.$refs.calendar.$refs.fullCalendar.getApi().prev()
    this.getEvents(moment(this.activeDate))
    this.$amplitude.logEvent('Calendar', { Action: 'Previous month' })
  }

  nextDatePickerMonth() {
    this.$refs.datePicker.getApi().next()
    this.getDatepickerEvents(moment(this.$refs.datePicker.getApi().currentData.currentDate))
  }
  goToTodayDatePicker() {
    this.$refs.datePicker.getApi().today()
    this.getDatepickerEvents(moment(this.$refs.datePicker.getApi().currentData.currentDate))
  }
  prevDatePickerMonth() {
    this.$refs.datePicker.getApi().prev()
    this.getDatepickerEvents(moment(this.$refs.datePicker.getApi().currentData.currentDate))
  }

  async getEvents(startDate: moment.Moment) {
    this.events = []
    this.calendarEvents = []
    this.eventsV2 = []
    const formattedStartDate = moment(startDate).subtract(1, 'month').startOf('month')
    const formattedEndDate = moment(startDate).add(1, 'month').endOf('month')

    const calendarEvents = await CalendarApi.getCombinedClientEvents(
      this.userState.clientId,
      formattedStartDate.format('YYYY-MM-DD'),
      formattedEndDate.format('YYYY-MM-DD')
    )
    calendarEvents.forEach(event => {
      this.eventsV2.push(CalendarEventItemV2Model.toFullCalendarEvent(event))
    })
  }

  async getDatepickerEvents(startDate: moment.Moment) {
    this.daysWithEvent = []
    const calendarEvents = await CalendarApi.getCombinedClientEvents(
      this.userState.clientId,
      startDate.startOf('month').format('YYYY-MM-DD'),
      startDate.endOf('month').format('YYYY-MM-DD')
    )
    Array.from(new Set([...calendarEvents].map(e => moment(e.startTime).format('YYYY-MM-DD')))).forEach(e =>
      this.daysWithEvent.push({ start: e })
    )
  }

  handleDateSelect(selectInfo: DateClickArg) {
    this.$refs.datePicker.getApi().select(selectInfo.dateStr)
    this.$amplitude.logEvent('Calendar', { Action: 'Date picked' })
    if (moment(selectInfo.dateStr).isSame(this.activeDate, 'month') && getMobileOrDesktop() === 'desktop') {
      this.activeDate = moment(selectInfo.dateStr)
      return
    }
    this.activeDate = moment(selectInfo.dateStr)
    this.$refs.calendar.$refs.fullCalendar.getApi().gotoDate(selectInfo.dateStr)
    if (getMobileOrDesktop() === 'mobile' && !moment(selectInfo.dateStr).isSame(this.activeDate, 'month')) {
      this.getEvents(moment(selectInfo.dateStr))
      this.getDatepickerEvents(moment(selectInfo.dateStr))
    }
    this.getEvents(moment(selectInfo.dateStr))
  }

  handleResize() {
    this.isMobileDevice = document.body.clientWidth <= 1024
  }

  async created() {
    window.addEventListener('resize', this.handleResize)
    await this.getEvents(this.activeDate)
    if (getMobileOrDesktop() === 'mobile') {
      this.getDatepickerEvents(this.activeDate)
    } else {
      Array.from(new Set([...this.eventsV2].map(e => moment(e.start).format('YYYY-MM-DD')))).forEach(e =>
        this.daysWithEvent.push({ start: e })
      )
    }
    this.$amplitude.logEvent('Calendar', { Action: 'Load calendar' })
  }

  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  }
}

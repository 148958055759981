












import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import Auth from '@/shared/storeModules/auth'
import moment from 'moment'
import FullCalendar, { CalendarOptions, EventApi, EventClickArg } from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import { IFullCalendarEvent } from '@/shared/components/interfaces/calendar.interface'
import tippy, { roundArrow } from 'tippy.js'
import 'tippy.js/dist/svg-arrow.css'
import EventDetails from '@/components/calendar/EventDetails.vue'

const userState = getModule(Auth)

@Component({
  components: { FullCalendar, EventDetails }
})
export default class DesktopCalendar extends Vue {
  userState = userState
  activeEvent: EventApi | null = null
  @Prop({ required: true }) events: IFullCalendarEvent[]
  @Prop({ required: true }) eventsV2: IFullCalendarEvent[]
  @Prop() activeDate: moment.Moment

  get calendarOptions(): CalendarOptions {
    return {
      plugins: [dayGridPlugin, interactionPlugin],
      initialView: 'dayGridMonth',
      locale: `${this.$locale}`,
      firstDay: 1,
      headerToolbar: { start: 'title', end: 'prev,today,next' },
      customButtons: {
        next: {
          click: () => this.$emit('next')
        },
        today: {
          text: `${this.$t('buttons.today')}`,
          click: () => this.$emit('today')
        },
        prev: {
          click: () => this.$emit('prev')
        }
      },
      eventClick: this.showEventDetails,
      eventSources: [JSON.parse(JSON.stringify(this.events)), JSON.parse(JSON.stringify(this.eventsV2))],
      eventTimeFormat: {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      },
      dayHeaderContent: args => {
        return moment(args.date).format('ddd').slice(0, 1)
      },
      dayCellContent: args => {
        return moment(args.date).format('D')
      }
    }
  }

  showEventDetails(target: EventClickArg) {
    if (!moment(target.event.startStr).isSame(this.activeDate, 'month')) return
    const template = document.getElementById('tooltip')
    this.activeEvent = target.event
    tippy(target.el, {
      allowHTML: true,
      arrow: roundArrow,
      placement: 'top',
      interactive: true,
      trigger: 'click',
      showOnCreate: true,
      animation: false,
      maxWidth: 325,
      onHide(instance) {
        instance.popper.querySelector('.event-details-close')?.removeEventListener('click', () => {
          instance.hide()
        })
      },
      onMount(instance) {
        instance.setContent(template!.innerHTML)
        instance.popper.querySelector('.event-details-close')?.addEventListener('click', () => {
          instance.hide()
        })
      },
      onHidden(instance) {
        instance.destroy()
      }
    })
    this.$amplitude.logEvent('Calendar', { Action: 'Display event' })
  }
}

















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import Auth from '@/shared/storeModules/auth'
import moment from 'moment'
import FullCalendar, { CalendarOptions, EventApi, EventClickArg, EventContentArg, MountArg } from '@fullcalendar/vue'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import { IFullCalendarEvent } from '@/shared/components/interfaces/calendar.interface'
import EventDetails from './EventDetails.vue'

const userState = getModule(Auth)

@Component({
  components: { FullCalendar, EventDetails }
})
export default class MobileCalendar extends Vue {
  @Prop({ required: true }) events: IFullCalendarEvent[]
  @Prop({ required: true }) eventsV2: IFullCalendarEvent[]
  @Prop() activeDate: moment.Moment
  $t: (str: string) => ''
  userState = userState
  activeEvent: EventApi | null = null
  showDetails = false

  get calendarOptions(): CalendarOptions {
    return {
      plugins: [listPlugin, interactionPlugin],
      initialView: 'listDay',
      locale: `${this.$locale}`,
      eventDidMount: this.renderEvent,
      eventSources: [JSON.parse(JSON.stringify(this.events)), JSON.parse(JSON.stringify(this.eventsV2))],
      eventClick: this.handleEventClick,
      eventTimeFormat: {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      },
      height: '100%',
      contentHeight: '100%',
      headerToolbar: false
    }
  }

  renderEvent(arg: MountArg<EventContentArg>) {
    let timeLabel = moment(arg.event.start!).format('HH:mm')
    if (arg.event.allDay) {
      return (arg.el.children[0].innerHTML = this.$t('labels.calendar.tooltip.allDay'))
    }
    if (arg.event.end && !moment(arg.event.start!).isSame(moment(arg.event.end), 'day')) {
      if (arg.isStart) {
        return (arg.el.children[0].innerHTML = timeLabel)
      } else if (arg.isEnd) {
        return (arg.el.children[0].innerHTML = `Ending ${moment(arg.event.end).format('HH:mm')}`)
      } else {
        return (arg.el.children[0].innerHTML = this.$t('labels.calendar.tooltip.allDay'))
      }
    }
    if (arg.event.start === arg.event.end || !arg.event.end) {
      return (arg.el.children[0].innerHTML = moment(arg.event.start!).format('HH:mm'))
    }
    return (arg.el.children[0].innerHTML = `${moment(arg.event.start!).format('HH:mm')} - ${moment(
      arg.event.end
    ).format('HH:mm')}`)
  }

  handleEventClick(target: EventClickArg) {
    this.toggleShowDatails()
    this.activeEvent = target.event
    this.$amplitude.logEvent('Calendar', { Action: 'Display event' })
  }

  toggleShowDatails() {
    this.showDetails = !this.showDetails
  }
}

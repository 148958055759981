import moment from 'moment'
import { IFullCalendarEvent } from '../components/interfaces/calendar.interface'

/**
 * This is a model working over the IFullCalendarEvent interface
 *
 * Functions should be pure, static and accept the IFullCalendarEvent
 * interface as the first parameter.
 *
 * The only exception to accepting IFullCalendarEvent as the first
 * parameter is when using the class as a static factory to build
 * the interface from another data structure.
 *
 * Example:
 * public static from_form(form: IFullCalendarEvent): ICalendarEventItem
 */

export default abstract class FullCalendarEventModel {
  public static fullCalendarIsMultiDayEvent(event: IFullCalendarEvent): boolean {
    return event.allDay
      ? !moment(event.start).isSame(moment(event.end).subtract(1, 'day'), 'day')
      : !moment(event.start).isSame(event.end, 'day')
  }
}

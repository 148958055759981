import moment from 'moment'
import {
  IFullCalendarEvent,
  ICalendarEventItem,
  ICalendarEventItemV2,
} from '../components/interfaces/calendar.interface'

/**
 * This is a model working over the ICalendarEventItem interface
 *
 * Functions should be pure, static and accept the ICalendarEventItem
 * interface as the first parameter.
 *
 * The only exception to accepting ICalendarEventItem as the first
 * parameter is when using the class as a static factory to build
 * the interface from another data structure.
 *
 * Example:
 * public static from_form(form: ICalendarEventItem): ICalendarEventItem
 */

export default abstract class CalendarEventItemModel {
  public static toFullCalendarEventLegacy(event: ICalendarEventItem): IFullCalendarEvent {
    return {
      id: '',
      title: event.title,
      description: event.description,
      start: event.date,
      end: moment(event.date)
        .add(1, 'day')
        .format('YYYY-MM-DD'),
      allDay: true,
      extendedProps: {
        type: 'schedule',
        isRecurring: false,
        link: `agreement:${event.agreementId}`,
      },
    }
  }

  public static toCalendarEventV2(event: ICalendarEventItem): ICalendarEventItemV2 {
    return {
      id: '',
      title: event.title,
      description: event.description,
      startTime: moment(event.date)
        .startOf('day')
        .format(),
      endTime: moment(event.date)
        .startOf('day')
        .add(1, 'day')
        .format(),
      type: 'schedule',
      link: 'agreement:' + event.agreementId,
      ical: '',
      isRecurring: true,
    }
  }
}































import moment from 'moment'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { IFullCalendarEvent, ICalendarEventItemV2 } from '@/shared/components/interfaces/calendar.interface'
import FullCalendarEventModel from '@/shared/models/FullCalendarEventModel'
import CalendarEventItemV2Model from '@/shared/models/CalendarEventItemV2Model'

@Component({})
export default class EventDetails extends Vue {
  @Prop() event: IFullCalendarEvent
  @Prop() showDetails: boolean
  @Prop() isMobileDevice: boolean

  get eventStartDate() {
    return moment(this.event.start).format('ddd D MMM')
  }

  get eventEndDate() {
    return this.event.allDay
      ? moment(this.event.end).subtract(1, 'day').format('ddd D MMM')
      : moment(this.event.end).format('ddd D MMM')
  }

  get eventAllDayRange() {
    return FullCalendarEventModel.fullCalendarIsMultiDayEvent(this.event)
      ? `${this.eventStartDate} - ${this.eventEndDate}`
      : this.eventStartDate
  }

  get isFlexible() {
    return (
      !CalendarEventItemV2Model.isAllDay(this.event as unknown as ICalendarEventItemV2) &&
      this.event.extendedProps.type === 'arrival' &&
      this.event.end &&
      this.event.start !== this.event.end
    )
  }

  get eventTimeRange() {
    const startsText = this.isFlexible ? this.$t('labels.calendar.tooltip.startingBetween') + ' ' : ''
    if (!this.event.end) {
      return `${startsText}${this.eventStartDate} ${moment(this.event.start).format('HH:mm')} `
    }
    return FullCalendarEventModel.fullCalendarIsMultiDayEvent(this.event)
      ? `${startsText}${this.eventStartDate}, ${moment(this.event.start).format('HH:mm')} - ${
          this.eventEndDate
        }, ${moment(this.event.end).format('HH:mm')}`
      : `${startsText}${this.eventStartDate} ${moment(this.event.start).format('HH:mm')} - ${moment(
          this.event.end
        ).format('HH:mm')}`
  }

  closeDetails() {
    this.$emit('toggle')
  }
}

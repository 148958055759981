import axios from './interceptor'
import { ICalendarEventItemV2 } from '../../components/interfaces/calendar.interface'
import AgreementsApi from '@/shared/services/api/agreementsApi'
import CalendarEventItemModel from '../../models/CalendarEventItemModel'
import { ICalendarEventItem } from '../../components/interfaces/calendar.interface'
import Calendar from '@/shared/billie/api/calendar'

export default class CalendarApi {
  static async getCombinedClientEvents(
    clientId: string,
    startDate: string,
    endDate: string
  ): Promise<ICalendarEventItemV2[]> {
    const newEvents = await new Calendar(axios).list(clientId, startDate, endDate)
    const oldEvents = await AgreementsApi.getClientCalendarEntries(clientId, startDate, endDate)
    return [
      ...newEvents,
      ...(Object.values(oldEvents) as ICalendarEventItem[][]).flat().map(CalendarEventItemModel.toCalendarEventV2),
    ]
  }
}
